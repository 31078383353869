:root {
    --navbarBackground: #eeeeee66;
    --navbarActiveColor: #fefefe66;
    --navbarActiveShadow: #0000004a;
    
    @media(prefers-color-scheme: dark) {
        --navbarBackground: #55555555;
        --navbarActiveColor: #66666666;
        --navbarActiveShadow: black;
    }
}

.navbar {
    position: fixed;
    padding: 4px;
    border-radius: 16px;
    color: var(--textColor);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background-color: var(--navbarBackground);
    box-shadow: 
        -0.5px -0.5px 1px #00000033 inset,
        0.5px 0.5px 1px #ffffff33 inset,
        0 0 4px #00000033;
        
    &&.mobile {
        top: 80px;
        right: 16px;
        
        &&.collapsed {
            display: none;
        }
        
        &&.expanded {
            display: block;
        }
    }
    
    &&.desktop {
        top: 12px;
        left: 12px;
    }
    
    z-index: 1000;
}


.navbarList {
    padding: 0;
    margin-top: 3px;
    margin-bottom: 3px;
    list-style-type: none;
}

.navbarListItem {
    margin-left: 3px;
    margin-right: 3px;
        
    &&.mobile {
        display: block;
        padding-left: 15px;
        padding-right: 45px;
        padding-top: 15px;
        padding-bottom: 15px;
        
        &&:not(:last-child) {
            margin-bottom: 18px;
        }
    }
    
    &&.desktop {
        display: inline-block;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        padding-right: 15px;
        
        &&:not(:last-child) {
            margin-right: 12px;
        }
    }
    
    &&.active {
        border-radius: 10px;
        background-color: var(--navbarActiveColor);
        box-shadow: 
            0.5px 0.5px 0.5px #ffffff33 inset,
            -0.5px -0.5px 0.5px #00000033 inset,
            0 0.5px 7px var(--navbarActiveShadow);
        backdrop-filter: saturate(1.5);
    }
}

.navbarLink {
    font-family: var(--sansSerif);
    color: var(--primaryTextColor);
    text-decoration: none;
    
    &&.mobile {
        font-size: 26px;
    }
    
    &&.desktop {
        font-size: 18px;
    }
}

.collapseButton {
    background: none;
    outline: none;
    border: none;
    background-color: var(--navbarBackground);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    top: 16px;
    right: 16px;
    padding: 12px;
    width: 52px;
    height: 52px;
    border-radius: 26px;
    position: fixed;
    z-index: 20;
    box-shadow: 
        -0.5px -0.5px 1px #00000033 inset,
        0.5px 0.5px 1px #ffffff33 inset,
        0 0 4px #00000033;
        
    &&.collapsed {
        background-color: var(--navbarActiveColor);
    }
    
    &&.expanded {
        padding-top: 15px;
    }
}