.textEntry {
    padding: 10px;
    margin: 10px 0;
    background-color: var(#00000000);
    border: none;
    border-radius: 10px;
    color: var(--textColor);
    background-color: var(--backgroundColor3);
    width: fill;
    font-size: var(--regularFontSize);
    font-family: var(--sansSerif);
    box-shadow: 0 0 8px #00000018;
    resize: vertical;
}