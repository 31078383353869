@import './textStyles.css';
@import './colors.css';

:root {
    --shadow: 0px 0px 6px #00000022;
}

body {
    background-color: var(--backgroundColor);
    margin: 0;
}

.primaryButton {
    border-radius: 8px;
    border: none;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    font-family: var(--sansSerif);
    font-size: 18px;
}

.secondaryLink {
    text-decoration: none;
    font-family: var(--sansSerif);
    font-size: 18px;
}

.groupedItem {
    margin-top: 8px;
    margin-left: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.groupedItem:last-child {
    margin-right: 8px;
}

.backgroundBlur {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background-color: var(--navbarBackground);
    box-shadow:
        -0.5px -0.5px 1px #00000033 inset,
        0.5px 0.5px 1px #ffffff33 inset,
        0 0 4px #00000033;
}