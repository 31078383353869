button {
    border: none;
    text-align: center;
    text-decoration: none;
    font-family: var(--sansSerif);
    font-size: var(--regularFontSize);
}

button.default {
    border-radius: 10px;
    box-shadow: 0 0 6px #00000010;
    padding: 12px 24px;
}

button.small {
    border-radius: 8px;
    padding: 6px 12px;
}

button.underlined {
    text-decoration: underline;
}