:root {
    /* Background Colors */
    --backgroundColor: #eee;
    --backgroundColor2: #f5f5f5;
    --backgroundColor3: white;
        
    /* Text Colors */
    --textColor: black;
    --textColor2: #333;
    --textColor3: #555;
    --textColorOpposite: white;

    /* Colors */
    --cyan: #0599b3;
    --darkCyan: #045d6d;
    --green: #00a876;
    --darkGreen: #016e4d;
    --yellow: #f4ff57;
    --orange: #f55e00;
    --pink: #ff8396;
    --red: #e5091b;
    --darkRed: #a8000e;
    
    /* Dark mode */
    @media(prefers-color-scheme: dark) {
        /* Background Colors */        
        --backgroundColor: #1e2021;
        --backgroundColor2: #2c3134;
        --backgroundColor3: #41494e;
                
        /* Text Colors */
        --textColor: white;
        --textColor2: #aaa;
        --textColor3: #777;
        --textColorOpposite: black;
        
        /* Colors */
        --cyan: #08a0bb;
        --darkCyan: #05768a;
        --green: #0ec58e;
        --darkGreen: #00865e;
        --yellow: #fffb00;
        --orange: #ff6607;
        --pink: #f35970;
        --red: #de0012;
        --darkRed: #a4000e;
    }
}