:root {
    --border: 2px solid var(--backgroundColor);
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #00000000;
    border-radius: 8px;
    background-color: var(--backgroundColor2);
    box-shadow: 0 0 10px #00000018;
    overflow: hidden;
    width: fill;
}

.headerRow {
    background-color: var(--backgroundColor3);
    
    && p {
        float: left;
    }
}

td, th {
    padding: 10px;
}

td:first-child, th:first-child {
    padding-left: 10px;
    border-left: none;
}

td, th {
    border-left: var(--border);
    border-bottom: var(--border);
}

td {
    padding-top: 6px;
    padding-bottom: 6px;
}

tr:last-child td {
    border-bottom: none;
}

.tableListList {
    padding: 0;
    list-style: none;
    background-color: var(--backgroundColor2);
    border-radius: 12px;
    box-shadow: 0 0 10px #00000018;
}

.tableListListRow {
    padding: 12px;
}

.tableListListRow:not(:last-child) {
    border-bottom: var(--border);
}