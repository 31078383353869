@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
    /* Fonts */
    --sansSerif: 'Poppins', sans-serif;
    --monospaced: 'Roboto Mono', monospace;
    
    /* Font Weights */
    --thinWeight: 200;
    --regularWeight: 300;
    --semiboldWeight: 500;
    --boldWeight: 600;
    --heavyWeight: 900;
    
    /* Font Sizes */
    --smallFontSize: 16px;
    --regularFontSize: 18px;
    --largeFontSize: 20px;
    --xlFontSize: 28px;
}

h1 {
    font-family: var(--sansSerif);
    font-weight: var(--boldWeight);
    font-size: var(--xxlFontSize);
    color: var(--textColor);
    margin: px;
}

h2 {
    font-family: var(--sansSerif);
    font-weight: var(--regularWeight);
    font-size: var(--xlFontSize);
    color: var(--textColor);
    margin: 2px;
}

h3 {
    font-family: var(--sansSerif);
    font-weight: var(--semiboldWeight);
    font-size: var(--largeFontSize);
    color: var(--textColor2);
    margin: 2px;
}

h4 {
    font-family: var(--sansSerif);
    font-weight: var(--regularWeight);
    font-size: var(--regularFontSize);
    color: var(--textColor3);
    margin: 2px;
}

p {
    font-family: var(--sansSerif);
    font-weight: var(--regularWeight);
    font-size: var(--regularFontSize);
    color: var(--textColor);
    margin: 2px;
}


small {
    font-family: var(--sansSerif);
    font-weight: var(--regularFontWeight);
    font-size: var(--smallFontSize);
    color: var(--textColor3);
}

em {
    font-weight: var(--semiboldWeight);
}

b {
    font-weight: var(--boldWeight);
}

strong {
    font-weight: var(--heavyWeight);
}

i {
    font-style: italic;
}

u {
    text-decoration: underline;
}

code {
    font-family: var(--monospaced);
    font-weight: var(--regularWeight);
    font-size: var(--regularFontSize);
    color: var(--textColor);
}

a {
    color: var(--darkCyan);
}

a:visited {
    color: var(--darkCyan);
}

li::marker {
    color: var(--textColor);
}