body {
    padding: 0;
}

.pageHeader {
    top: 0px;
    
    &&.mobile {
        padding-right: 60px;
    }
}

.pageTitle {
    margin: 0;
    font-size: 32px;
    padding: 3.5px 0;
    width: 100%;
    word-wrap: break-word;
}